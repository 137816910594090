.amedia-menu .classifieds {
  background: #e7e6e2;
  box-sizing: content-box;
  padding: 10px 0;
  width: 100%;
  position: relative;
  display: flex;
  min-height: 32px;
  align-items: center;
  flex-wrap: wrap;
}

.amedia-menu .classifieds::before {
  background: #e7e6e2;
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 2000px;
  z-index: 10;
}

.amedia-menu .classifieds::after {
  background: #e7e6e2;
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 2000px;
  z-index: 10;
}

.amedia-menu img.classifieds-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -7px;
}

.amedia-menu .classifieds a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-decoration: none;
  color: #292827;
  margin-right: 16px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.amedia-menu .classifieds a:hover {
  background: transparent;
}

.amedia-menu .classifieds h2 {
  display: inline-block;
  margin-right: 16px;
  padding: 0;
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: 20px;
}

.amedia-menu .catalog {
  display: block;
  line-height: 1;
  flex-basis: 100%;
  margin-top: 10px;
}

.amedia-menu .catalog form {
  display: none;
}

.amedia-menu .catalog a,
.amedia-menu .catalog img,
.amedia-menu .catalog.show-search form {
  display: inline-block;
  vertical-align: middle;
}

.amedia-menu .catalog.show-search a {
  display: none;
}

.amedia-menu .catalog img {
  margin-top: 3px;
  position: relative;
  top: 1px;
  cursor: pointer;
}

.amedia-menu .catalog a {
  font-weight: 400;
  margin-right: 0;
}

.amedia-menu .catalog label {
  display: inline-block;
  width: 1px;
  height: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.amedia-menu .catalog input[type='search'] {
  border: 1px solid #aeaaa6;
  height: 30px;
  box-sizing: border-box;
  -webkit-apperance: none;
  font-size: 13px;
  width: 250px;
  padding-left: 10px;
  padding-right: 42px;
  vertical-align: middle;
}

.amedia-menu .catalog button {
  border: 0 none;
  border-left: 1px solid #aeaaa6;
  background: transparent;
  display: inline-block;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0 0 0 -38px;
  text-align: center;
  vertical-align: middle;
}

.amedia-menu .catalog button::before {
  content: '\f122';
  font-family: glyphs;
  color: #686563;
}

@media screen and (min-width: 1024px) {
  .amedia-menu .classifieds {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .amedia-menu .classifieds .catalog {
    flex-basis: auto;
    margin-left: auto;
    margin-top: 0;
  }
}
